import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SelectedServices = ({ navigate, ServiceId, onClick, disable }) => {
  // const { address, first_name, last_name, provider_images } =
  //   Services?.[0]?.provider[0];
  // console.log(address,'address')
  const { Provider } = useSelector((state) => state.auth);
  const { provider_category_detail } = Provider;
  const { SelectedService } = Provider?.provider_category_detail;

  console.log(SelectedService, "category_detassssss");
  return (
    <>
      <div
        className={
          window.location.pathname.includes("bookNow")
            ? "card p-4 rounded"
            : "customshadow p-4 rounded"
        }
      >
        <div className="d-flex gap-2">
          <img
            alt="provider"
            src={provider_category_detail?.provider_images?.[0]?.image}
            style={{ width: "100px", borderRadius: "10px" }}
          />

          <div>
            <h5 className="text-dark fw-bold">
              {provider_category_detail?.first_name}{" "}
              {provider_category_detail?.last_name}
            </h5>
            <p className="text-dark fw-bold">
              {provider_category_detail?.address}
            </p>
          </div>
        </div>

        <div className="w-full mt-5" style={{ border: "1px solid gray" }}></div>

        <div className="mt-4">
          <div
            style={{
              maxHeight: "50vh",
              overflowY: "auto",
              padding: "20px",
            }}
          >
            {SelectedService?.map((e) => {
              return (
                <div className="w-full" key={e.id}>
                  <div className="d-flex gap-2 justify-content-between">
                    <div>
                      <p className="text-dark fw-bold">{e.name}</p>
                      <p
                        className="text-dark fw-bold"
                        style={{ marginTop: "-25px" }}
                      >
                        {e?.time && `${e?.time} min`}
                        {e?.selectProfessional?.first_name ? (
                          <>
                            {" "}
                            by <i>{e?.selectProfessional?.first_name} </i>
                            {e?.timeSlot && `at ${e?.timeSlot}`}{" "}
                            {e?.dateslot && `on ${e?.dateslot} `}
                          </>
                        ) : (
                          <>
                            {" "}
                            by <i>any</i> {e?.timeSlot && `at ${e?.timeSlot}`}{" "}
                            {e?.dateslot && `on ${e?.dateslot} `}
                          </>
                        )}
                      </p>
                    </div>
                    <div>
                      <p className="text-dark fw-bold">OMR {e.price}</p>
                    </div>
                  </div>
                </div>
              );
            })}

            {/* Calculate total price */}
          </div>
          <div className="w-full px-3 mt-3">
            <div className="d-flex gap-2 justify-content-between">
              <div>
                <p className="text-dark fw-bold">Total</p>
              </div>
              <div>
                <p className="text-dark fw-bold">
                  OMR{" "}
                  {
                    SelectedService?.reduce(
                      (total, e) => Number(total) + Number(e.price),
                      0
                    ) // Calculate total price
                  }
                </p>
              </div>
            </div>
          </div>
          {onClick ? (
            <button
              disabled={disable}
              style={{ backgroundColor: disable ? "#36454F" : "#000" }}
              onClick={onClick}
              className="btn-defaultS w-100 rounded text-center"
            >
              {disable ? "Slots Not Available" : "Continue"}
            </button>
          ) : (
            <Link
              disabled={disable}
              style={{ backgroundColor: disable ? "#36454F" : "#000" }}
              to={navigate}
              state={ServiceId}
              className="btn-defaultS w-100 rounded text-center"
            >
              Continue
            </Link>
          )}
        </div>
      </div>
    </>
  );
};
export default SelectedServices;
