/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";

export default function ContactHero() {
  return (
    <div>
      <section className="container mt-5">
        {/*Contact heading*/}
        <div className="row">
          {/*Grid column*/}
          <div className="col-sm-12 mb-4 col-md-5">
            {/*Form with header*/}
            <div className="card border-dark rounded-sm">
              <div className="card-header p-0">
                <div className=" bg-black text-white text-center py-2">
                  <h3>
                    <i className="fa fa-envelope" /> Write to us:
                  </h3>
                  <p className="m-0">
                    We’ll write rarely, but only the best content.
                  </p>
                </div>
              </div>
              <div className="card-body p-3">
                <div className="form-group mt-1">
                  <label> Your name </label>
                  <div className="input-group">
                    <input
                      defaultValue=""
                      type="text"
                      name="data[name]"
                      className="form-control rounded-pill contact-input"
                      id="inlineFormInputGroupUsername"
                      placeholder="Your name"
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label>Your email</label>
                  <div className="input-group mb-2 mb-sm-0">
                    <input
                      type="email"
                      defaultValue=""
                      name="data[email]"
                      className="form-control rounded-pill contact-input"
                      id="inlineFormInputGroupUsername"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label>Subject</label>
                  <div className="input-group mb-2 mb-sm-0">
                    <input
                      type="text"
                      name="data[subject]"
                      className="form-control rounded-pill contact-input"
                      id="inlineFormInputGroupUsername"
                      placeholder="Subject"
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label>Message</label>
                  <div className="input-group mb-2 mb-sm-0">
                    <input
                      type="text"
                      className="form-control rounded-pill"
                      placeholder="Write Your Message"
                      name="mesg"
                    />
                  </div>
                </div>
                <div className="text-center">
                  <input
                    type="submit"
                    name="submit"
                    defaultValue="submit"
                    className=" py-2 btn-defaultS mt-3"
                  />
                </div>
              </div>
            </div>
          </div>
          {/*Grid column*/}
          {/*Grid column*/}
          <div className="col-sm-12 col-md-7">
            {/*Google map*/}
            <div className="mb-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d313361.5614403908!2d-106.99404280783018!3d52.15001458074015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5304f6bf47ed992b%3A0x5049e3295772690!2sSaskatoon%2C%20SK%2C%20Canada!5e0!3m2!1sen!2s!4v1734972423382!5m2!1sen!2s"
                allowfullscreen
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                height={500}
                frameBorder={0}
                style={{ border: 0 }}
                aria-hidden="false"
                tabIndex={0}
              />
            </div>
            {/*Buttons*/}
            {/* <div className="row text-center">
        <div className="col-md-4">
          <a className="bg-primary px-3 py-2 rounded text-white mb-2 d-inline-block">
            <i className="fa fa-map-marker" />
          </a>
          <p> Your Address ….. </p>
        </div>
        <div className="col-md-4">
          <a className="bg-primary px-3 py-2 rounded text-white mb-2 d-inline-block">
            <i className="fa fa-phone" />
          </a>
          <p>+91- 90000000</p>
        </div>
        <div className="col-md-4">
          <a className="bg-primary px-3 py-2 rounded text-white mb-2 d-inline-block">
            <i className="fa fa-envelope" />
          </a>
          <p> your@gmail.com</p>
        </div>
      </div> */}
          </div>
          {/*Grid column*/}
        </div>
      </section>
    </div>
  );
}
