const HomeServices = () => {
  return (
    <div className="home-services" style={{ zIndex: "100" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {/* Section Title Start */}
            <div className="section-title">
              <h3 className="wow fadeInUp">Team Member Services</h3>
              <h2 className="text-anime">
                Grow your business with Saskatoon’s only beauty booking platform
                Get connected with local customers in your area
              </h2>
            </div>
            {/* Section Title End */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            {/* Service Item Start */}
            <div
              className="service-item-layout1 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-icon">
                <img src="assests/images/service-1.svg" alt="" />
              </div>
              <h3>Cutting &amp; Styling</h3>
              <p>
                Sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam
              </p>
            </div>
            {/* Service Item End */}
          </div>
          <div className="col-md-4">
            {/* Service Item Start */}
            <div
              className="service-item-layout1 wow fadeInUp"
              data-wow-delay="0.75s"
            >
              <div className="service-icon">
                <img
                  src="assests/images/dembell.svg"
                  alt=""
                  style={{ color: "white" }}
                />
              </div>
              <h3>Personal Training</h3>
              <p>
                Sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam
              </p>
            </div>
            {/* Service Item End */}
          </div>
          <div className="col-md-4">
            {/* Service Item Start */}
            <div
              className="service-item-layout1 wow fadeInUp"
              data-wow-delay="1.0s"
            >
              <div className="service-icon">
                <img src="assests/images/heart.svg" alt="" />
              </div>
              <h3>Mental Health & Yoga</h3>
              <p>
                Sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam
              </p>
            </div>
            {/* Service Item End */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomeServices;
