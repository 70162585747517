/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
  MdOutlineTimer,
} from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import HearColor from "./HearColor";
import { FaArrowLeft, FaArrowRight, FaPlus } from "react-icons/fa";
import SelectProfessional from "./SelectProfessional";
import { TiTick } from "react-icons/ti";
import {
  selectedServices,
  selectedServicesNew,
  toggleService,
} from "../../Slice/serviceSlice";
import { axiosInstance } from "../../utils/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import SelectedServices from "./selectedServices";
// import DatePicker from "./DatePicker";

export default function Pakages({ service, provider }) {
  const { Services, Provider } = useSelector((state) => state.auth);
  const { SelectedService, provider_services } =
    Provider.provider_category_detail;
  console.log(Provider, "Selector");
  console.log(SelectedService, "SelectedService");

  const ServiceIds = Services?.map((service) => service.id);

  const [serviceName, setServiceName] = useState([]);
  const data2 = [
    { title: "Beard", color: "green", id: 1 },
    { title: "Beard", color: "black", id: 2 },
    { title: "Hair", color: "Blue", id: 3 },
    { title: "Beard", color: "Red", id: 4 },
    { title: "Hair", color: "Yellow", id: 5 },
    { title: "Beard", color: "green", id: 6 },
    { title: "Teeth", color: "green", id: 7 },
  ];

  const dispatch = useDispatch();
  const fetchProviders = async (id) => {
    Status(id);
    await axiosInstance
      .get(`category-service/${id}`)
      .then((res) => {
        if (res.data.category_service.services.length > 0) {
          setServiceName(res.data.category_service.services);
        }
      })
      .catch((err) => console.log(err));
  };

  const [selectedIds, setSelectedIds] = useState([]);
  console.log(selectedIds, "id");

  const handleToggle = (e) => {
    if (selectedIds.includes(e)) {
      // Remove ID if already selected
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== e));
    } else {
      // Add ID if not selected
      setSelectedIds([...selectedIds, e]);
    }
  };
  const data = [1, 2, 3, 4];
  const serviceType = [
    { name: "Bread", id: 1 },
    { name: "Hair", id: 2 },
    { name: "Bread Crumbs", id: 3 },
    { name: "Another", id: 4 },
    { name: "Bread New", id: 5 },
    { name: "Hair Color", id: 6 },
  ];

  const [haircolor, sethaircolor] = useState(false);
  const [continues, setcontinues] = useState(false);

  const scrollRef = useRef(null);

  const scrollLeft = () => {
    scrollRef.current.scrollBy({
      left: -200, // Adjust scroll distance as per your requirement
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({
      left: 200, // Adjust scroll distance as per your requirement
      behavior: "smooth",
    });
  };

  const [active, setactive] = useState("");
  const Status = (id) => {
    setactive(id);
  };
  console.log(service, "service");
  useEffect(() => {
    const id = service?.[0]?.id;
    if (id) {
      fetchProviders(id);
    }
  }, [service]); // Dependencies to re-run the effect

  console.log("ac", active);

  return (
    <>
      <div className="container pt-5" style={{ backgroundColor: "white" }}>
        <div className="row gap-2">
          <div className="col-md-7 col-12 gap-2">
            {continues ? (
              <SelectProfessional />
            ) : (
              <div>
                <h1 className="text-dark">Services</h1>
                <div className="w-full" style={{ border: "1px solid black" }} />
                {/* Left Arrow */}
                {serviceName?.length < 0 ? <h1>Service Not Found</h1> : ""}
                {/* Scrollable Buttons Container */}

                <Swiper
                  className="mt-2"
                  slidesPerView={4}
                  spaceBetween={2}
                  navigation={{
                    nextEl: ".next",
                    prevEl: ".prev",
                  }}
                  modules={[Navigation]}
                >
                  {service?.map((e) => {
                    return (
                      <SwiperSlide key={e.id}>
                        <button
                          className="btn-defaultS"
                          style={{
                            backgroundColor:
                              e.id === active ? "black" : "white",
                            color: e.id === active ? "white" : "black",
                            border:
                              e.id === active ? "1px solid black" : "none",
                          }}
                          onClick={() => fetchProviders(e.id)}
                        >
                          {e.name}
                        </button>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                {serviceName?.map((e) => {
                  const isSelected = SelectedService?.some(
                    (service) => service.id === e.id
                  );
                  console.log(isSelected, "isSelected");
                  return (
                    <div
                      key={e.id}
                      className={`border border-gray p-3 d-flex flex-wrap justify-content-between align-items-center rounded mt-2`}
                      style={{
                        pointerEvents: !e?.professionals ? "none" : "",
                        backgroundColor: isSelected ? "black" : "white",
                      }}
                    >
                      <div>
                        <h6 className={isSelected ? "text-white" : "text-dark"}>
                          {e?.name}
                        </h6>
                        <p className={isSelected ? "text-white" : "text-dark"}>
                          <div className="d-flex gap-2">
                            {e?.discount ? (
                              <>
                                {/* Original Price with Strike-through */}
                                <h6 style={{ textDecoration: "line-through" }}>
                                  {e?.price} $
                                </h6>
                                {/* Discounted Price */}
                                <h6>
                                  {e?.price - (e?.price * e?.discount) / 100} $
                                  {/* Calculate discounted price */}
                                </h6>
                                {/* Discount Message */}
                                <h6 className="text-success">
                                  Save {e?.discount}%
                                </h6>
                              </>
                            ) : (
                              // Show the price normally if no discount
                              <h6>{e?.price} $</h6>
                            )}
                          </div>
                          <div>{e?.time} mins</div>
                          <div>
                            {!e?.professionals &&
                              "professionals is Not Available in This Service"}
                          </div>
                          <div>{e.description}</div>
                        </p>
                        <p
                          className={isSelected ? "text-white" : "text-dark"}
                        ></p>
                      </div>
                      <div onClick={() => handleToggle(e)}>
                        {isSelected ? (
                          <TiTick
                            onClick={() => {
                              const existingServices =
                                Provider.provider_category_detail
                                  .SelectedService || [];

                              // Check if the service with the same id already exists
                              const serviceExists = existingServices.some(
                                (service) => service.id === e.id
                              );

                              if (serviceExists) {
                                // Remove the service if it exists
                                const updatedServices = existingServices.filter(
                                  (service) => service.id !== e.id
                                );
                                dispatch(
                                  selectedServicesNew({
                                    ...Provider,
                                    provider_category_detail: {
                                      ...Provider.provider_category_detail,
                                      SelectedService: updatedServices, // Update the services without the removed one
                                    },
                                  })
                                );
                                console.log(
                                  "Service removed from SelectedService:",
                                  e
                                );
                              } else {
                                console.log(
                                  "Service already exists in SelectedService:",
                                  e
                                );
                              }
                              // dispatch(toggleService(e)); // Uncomment if you still need this
                            }}
                            style={{
                              backgroundColor: "#F2F2F2",
                              width: "28px",
                              height: "28px",
                              padding: "6px",
                              borderRadius: "8px",
                            }}
                          />
                        ) : (
                          <FaPlus
                            onClick={() => {
                              const existingServices =
                                Provider.provider_category_detail
                                  .SelectedService || [];

                              // Check if the service with the same id already exists
                              const serviceExists = existingServices.some(
                                (service) => service.id === e.id
                              );

                              if (!serviceExists) {
                                dispatch(
                                  selectedServicesNew({
                                    ...Provider,
                                    provider_category_detail: {
                                      ...Provider.provider_category_detail,
                                      SelectedService: [
                                        ...existingServices,
                                        e, // Add the new service if it doesn't exist
                                      ],
                                    },
                                  })
                                );
                              } else {
                                console.log(
                                  "Service already exists in SelectedService:",
                                  e
                                );
                              }
                              // dispatch(toggleService(e)); // Uncomment if you still need this
                            }}
                            style={{
                              backgroundColor: "#F2F2F2",
                              width: "28px",
                              height: "28px",
                              padding: "6px",
                              borderRadius: "8px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div className="col-md-4 col-12">
            <SelectedServices
              // categoryprofile={provider}
              navigate={"/professionals"}
              // ServiceId={ServiceIds}
            />
          </div>
        </div>
      </div>
    </>
  );
}
