/* eslint-disable no-unused-vars */
import moment from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { axiosInstance } from "../../utils/axiosInstance";
import Availablity from "./Availablity";

const DateSlots = ({ data, slot, setSlot, setdisable, disable }) => {
  const { Provider } = useSelector((state) => state.auth);
  const { SelectedService } = Provider.provider_category_detail;
  const [Available, setAvailable] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    SelectedService[0]?.id || ""
  );

  const [days, setDays] = useState([]);
  const [activeMonthYear, setActiveMonthYear] = useState(
    moment().format("MMMM YYYY")
  );
  const [swiperInstance, setSwiperIndex] = useState(null);

  const getNextSixMonthsDates = () => {
    const daysArray = [];
    const today = moment();

    // Generate dates from today for the next 6 months
    const endDate = today.clone().add(6, "months");

    while (today.isBefore(endDate) || today.isSame(endDate, "day")) {
      daysArray.push({
        date: today.format("DD MMM YYYY"),
        dayName: today.format("ddd"),
        isSunday: today.day() === 0, // Disable Sundays
      });
      today.add(1, "days");
    }

    setDays(daysArray);

    if (daysArray.length > 0) {
      const firstDay = daysArray[0];
      const monthYear = moment(firstDay.date, "DD MMM YYYY").format(
        "MMMM YYYY"
      );
      setActiveMonthYear(monthYear);
    }
  };

  const handleSlideChange = (swiper) => {
    const activeSlideDate = days[swiper.activeIndex];
    if (activeSlideDate) {
      const monthYear = moment(activeSlideDate.date, "DD MMM YYYY").format(
        "MMMM YYYY"
      );
      setActiveMonthYear(monthYear);
    }
  };

  useEffect(() => {
    getNextSixMonthsDates();
  }, []);

  const getSlote = async () => {
    const formData = new FormData();
    formData.append("provider_id", Provider.id);
    formData.append(
      "provider_category_id",
      Provider.provider_category_detail?.provider_category?.id
    );

    try {
      const response = await axiosInstance.post("disable-date", formData);
    } catch (error) {
      console.log(error);

      toast.error("Something Went Wrong. Please try again.");
    }
  };
  console.log(Provider, "Provider");
  const getTimeSlot = async (date) => {
    const formData = new FormData();
    formData.append("provider_id", Provider.id);
    formData.append(
      "provider_category_id",
      Provider.provider_category_detail?.provider_category?.id
    );
    formData.append("date", moment(date).format("yyyy-MM-DD"));
    try {
      const response = await axiosInstance.post("time-slots", formData);
      const { data } = response?.data;
      setAvailable(data);
      if (data?.length > 0 && data) {
      } else {
      }
    } catch (error) {
      console.log(error);

      toast.error("Something Went Wrong. Please try again.");
    }
  };

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
    getSlote();
    setSlot({ day: "", time: "" });
    setAvailable([]);
  };

  return (
    <div className="card p-4">
      <div className="d-flex justify-content-center mb-3">
        {data && (
          <div className="col-2">
            <img
              className="rounded-circle text-center"
              style={{ width: 100, height: 100, objectFit: "cover" }}
              src={data.image}
              alt="rewiw"
            />
            <h5 className="text-center text-dark d-flex align-items-end justify-content-center gap-1">
              {data.first_name} {data.last_name}{" "}
              <MdOutlineKeyboardArrowDown color="#000" />
            </h5>
          </div>
        )}
      </div>
      <div className="mb-3">
        {SelectedService?.length && (
          <>
            <div>Select Service</div>
            <select
              value={selectedOption}
              onChange={handleSelectChange}
              className="select_Service mb-2"
            >
              <option value="" disabled>
                Select Service
              </option>
              {SelectedService.map((serviceName, index) => (
                <option
                  key={index}
                  // onClick={() => {
                  //   getSlote(serviceName?.id, serviceName?.id);
                  // }}
                  value={serviceName?.id}
                >
                  {serviceName?.name}
                </option>
              ))}
            </select>
          </>
        )}
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h6>{activeMonthYear}</h6>
          <div className="d-flex justify-content-between align-items-center">
            <MdOutlineKeyboardArrowLeft
              size={20}
              color="#000"
              className="prev"
              style={{
                pointerEvents: swiperInstance === 0 ? "none" : "auto",
                opacity: swiperInstance === 0 ? 0.5 : 1,
              }}
            />
            <MdOutlineKeyboardArrowRight
              size={20}
              color="#000"
              className="next"
              style={{
                pointerEvents:
                  swiperInstance >= days.length - 9 ? "none" : "auto",
                opacity: swiperInstance >= days.length - 9 ? 0.5 : 1,
              }}
            />
          </div>
        </div>
        <Swiper
          slidesPerView={9}
          spaceBetween={3}
          navigation={{
            nextEl: ".next",
            prevEl: ".prev",
          }}
          onSlideChange={(swiper) => {
            handleSlideChange(swiper);
            setSwiperIndex(swiper.activeIndex);
          }}
          modules={[Navigation]}
          className="mySwiper"
        >
          {days.map((dayObj, index) => (
            <SwiperSlide
              className={`mb-2 ${
                dayObj.isSunday ? "disableSlot" : "activeSlot"
              }`}
              onClick={() => {
                getTimeSlot(dayObj.date);
                !dayObj.isSunday &&
                  setSlot({
                    ...slot,
                    day: dayObj.date,
                  });
              }}
              key={index}
            >
              <div
                className="rounded-circle border d-flex flex-column align-items-center justify-content-center"
                style={{
                  height: 60,
                  width: 60,
                  background: dayObj.date === slot.day ? "lightgrey" : "white",
                }}
              >
                <h6 className="m-0">{dayObj.date?.split(" ")[0]}</h6>
                <h6 className="m-0 text-center">
                  {dayObj.date?.split(" ")[1]}
                </h6>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <Availablity
        setdisable={setdisable}
        disable={disable}
        selectedService={selectedOption}
        Available={Available}
        slot={slot}
        setSlot={setSlot}
      />
    </div>
  );
};

export default DateSlots;
